import React from 'react';
import { Helmet } from 'react-helmet';
import BusinessCard from './BusinessCard';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Hanen Ltifi, MBA</title>
        <meta name="description" content="Hanen Ltifi, MBA: Project Management Specialist, Master of Business Administration - MBA, 
Master of Quality, Safety, and Environmental Management - QSE" />
        <meta name="keywords" content="Hanen Ltifi, MBA, Project Management Specialist, Master of Business Administration, MBA, 
Master of Quality, Safety, Environmental Management, QSE" />
      </Helmet>
      <BusinessCard />
    </div>
  );
}

export default App;
