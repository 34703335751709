import React, { useState } from 'react';
import { Card, CardContent, Avatar, Typography, Box, Grid, Button } from '@mui/material';

const BusinessCard = () => {
  const [showContactInfo, setShowContactInfo] = useState(false);

  // Encoded contact information
  const encodedContactInfo = {
    address: 'TW9udHJlYWwgUUMsIENhbmFkYQ==',
    phone: 'KzEgNTE0LTU2Ny0wNjUz',
    email: 'aGFuZW5AbHRpZmkuY29t'
  };

  // Decode base64 encoded strings
  const decodeBase64 = (encoded) => atob(encoded);

  const toggleContactInfo = () => {
    setShowContactInfo(!showContactInfo);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
      }}
    >
      <Card
        sx={{
          maxWidth: 345,
          boxShadow: 6,
          borderRadius: 3,
          padding: 2,
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        <Avatar
          alt="Hanen Ltifi"
          src={`${process.env.PUBLIC_URL}/images/Hanen-Ltifi.jpg`}
          sx={{
            width: 100,
            height: 100,
            margin: 'auto',
            marginBottom: 2,
            boxShadow: 3,
          }}a
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ textAlign: 'center', fontWeight: 'bold', color: '#333' }}
          >
            Hanen Ltifi
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ textAlign: 'center', fontStyle: 'italic', color: '#555' }}
          >
            Administrative Specialist
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: 'center', color: '#777', marginTop: 1 }}
          >
            Master of Business Administration - MBA
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: 'center', color: '#777' }}
          >
            Master of Quality, Safety, and Environmental Management - QSE
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2, justifyContent: 'center' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={toggleContactInfo}>
                {showContactInfo ? 'Hide Contact Info' : 'Show Contact Info'}
              </Button>
            </Grid>
            {showContactInfo && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary" sx={{ color: '#777' }}>
                    📍 {decodeBase64(encodedContactInfo.address)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary" sx={{ color: '#777' }}>
                    📞 <a href="tel:{decodeBase64(encodedContactInfo.phone)}">{decodeBase64(encodedContactInfo.phone)}</a>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary" sx={{ color: '#777' }}>
                    ✉️ <a href="mailto:{decodeBase64(encodedContactInfo.email)}">{decodeBase64(encodedContactInfo.email)}</a>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BusinessCard;
